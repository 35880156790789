/// <reference path="data.ts" />
/// <reference types="jquery" />
/// <reference types="highcharts" />
/// <reference path="highchart/highchartWrapper.ts" />
/// <reference path="highchart/simpleChart.ts" />
/// <reference path="highchart/dualColumnLineChart.ts" />
/// <reference path="d3/radial.ts" />
/// <reference path="trendgraph/trendgraph.ts" />

module Facetbase.Chart {
    export class Renderer {

        private config: FacetbaseChart;

        constructor(
            private jqRoot: JQuery,
            private inPdf: boolean,
            private silentFail: boolean,
            private language: string) {
        }

        loadData(jsonString: string) {
            try {
                this.config = jQuery.parseJSON(jsonString);
            }
            catch (ex) {
                //console.log('issue parsing chart JSON: ' + ex);
                //console.log(jsonString);
            }
        }

        // do the actual rendering
        render() {
            switch (this.config && this.config.data && this.config.data.length > 0 && this.config.type) {
                case "bar":
                case "column":
                case "spline":
                case "line":
                case "stackedbar":
                case "stackedcolumn":
                case "stackedarea":
                    new SimpleChart(this.jqRoot, this.config, this.inPdf, this.silentFail, this.language);
                    break;
                case "dualcolumnline":
                    new DualColumnLineChart(this.jqRoot, this.config, this.inPdf, this.silentFail, this.language);
                    break;
                case "radial":
                    new D3.Radial(this.jqRoot);
                    break;
                case "trendGraph":
                    new TrendGraph(this.jqRoot);
                    break;
                default:
                    //console.log(this.config && this.config.data && this.config.data.length > 0 && this.config.type);
                    this.jqRoot.parent().hide();       
            }
        }      
    }
}