/// <reference types="jquery" />

interface Window {
    Aspacts: any;
}

module Facetbase.Chart
{
    export class Ajax {

        CheckAjaxResponse(fnCallback, fnError, objRes) {
            // Ajax error handling
            if (objRes) {
                if (objRes.error) {
                    var strMessage = 'An unknown error has occured.';
                    if (objRes.error.Message) {
                        strMessage = 'Error: ' + objRes.error.Message;
                    }
                    if (fnError) {
                        fnError(strMessage);
                    } else {
                        console.log(strMessage);
                    }
                    return false;
                }
                fnCallback(objRes.value);
                return true;
            } else {
                console.log('Server request timed out.');
                return false;
            }
        }               

  
        // Render Chart
        renderChart(strChartAlias: string, fnCallback) {
            window.Aspacts.Products.Facetbase.CAP.Data.ChartRenderer.RazorChartGenerator.ChartDataTable(strChartAlias, this.CheckAjaxResponse.bind(this, fnCallback, null));
        }
    }    
}