/// <reference types="jquery" />
/// <reference types="d3" />

module Facetbase.Chart.D3 {

    export interface Category {
        Name: string;
        Score: number;
        Parts: Part[];
    }

    export interface Part {
        Name: string;
        Score: number;
        Visible: boolean;
    }

    export interface HMI {
        Updated: string;
        Score: number;
        Categories: Category[];
    }

    export type Mode = "pdf" | "simple" | "normal";

    export class Radial {

        // Configurable variables
        private margin = { top: 20, right: 20, bottom: 20, left: 20 };
        private barHeight = 290;
        private domain = [0, 10];
        private tickValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        private tickCircleValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        private transitionDuration = 400;
        private clearCenter = 60;
        private mode: Mode;
        private quadrant: number;

        // Scales & other useful things
        private numBars: number = null;
        private barScale: d3.ScaleLinear<number, number>;
        private keys: string[] = null;
        private realData: HMI;

        // Selected item
        private hoverSegment: Part;

        constructor(private root: JQuery, mode?: Mode, data?: HMI) {
            if (!mode) {
                mode = "normal";
            }
            
            this.mode = mode;
            if (data) {
                this.dataAvailable(data);
            } else {
                $.ajax('/api/hmi/current').then((data) => this.dataAvailable(data));
            }
        }

        private dataAvailable(data: HMI) {
            this.realData = data;
            this.realData.Categories.forEach(c => c.Parts.forEach(p => p.Visible = true));
            if (!(this.mode == "simple")) {
                this.update();
            }
        }

        private flatten(data: HMI): Part[] {
            var out: Part[] = [];
            if (data) {
                out.push.apply(out, data.Categories[1].Parts);
                out.push.apply(out, data.Categories[3].Parts);
                out.push.apply(out, data.Categories[2].Parts);
                out.push.apply(out, data.Categories[0].Parts);
            }
            return out;
        }

        // Pre-process data
        private initData(data: Part[]) {
            this.barScale = d3.scaleLinear().domain(this.domain).range([this.clearCenter, this.barHeight]);
            this.keys = data.map(x => x.Name);
            this.numBars = this.keys.length;
            for (var i = 0; i < data.length; i++) {
                if (this.quadrant !== null && this.quadrant !== undefined && Math.floor((i / 3)) != this.quadrant) {
                    data[i].Visible = false;
                } else {
                    data[i].Visible = true;
                }
            }
 
        }

        // Update data with a certain transition
        // allows for the 'wave' effect on the homepage
        public update(data?: Part[], transitionDuration?: number) {
            if (!data) {
                data = this.flatten(this.realData);
            }
            if (!!data) {
                this.chart(data, transitionDuration);
            }
        }

        private svgRotate(a: number) {
            return 'rotate(' + +a + ')';
        }

        private svgTranslate(x: number, y: number) {
            return 'translate(' + +x + ',' + +y + ')';
        }

        private initChart() {
            var svg = <any>d3.select(this.root[0])
                .selectAll("svg")
                .data([1]);

            svg = svg
                .enter()
                .append('svg')
                .attr("xmlns", "http://www.w3.org/2000/svg")
                .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
                .attr('viewBox', `0 0 ${(this.margin.left + this.barHeight) * 2} ${(this.margin.top + this.barHeight) * 2}`)
                .attr('preserveAspectRatio', 'xMidYMid')
                .attr('class', 'radial-wouter')
                //.attr('width', '540')
                //.attr('height', '540')
                .merge(svg);

            var root = svg.selectAll('g.radial-barchart')
                .data([1]);

            root = root
                .enter()
                .append('g')
                .classed('radial-barchart', true)
                .attr('transform', this.svgTranslate(this.margin.left + this.barHeight, this.margin.top + this.barHeight))
                .merge(root);

            var tickCircleContainer = root
                .selectAll('g.tick-circles')
                .data([1]);

            tickCircleContainer = tickCircleContainer
                .enter()
                .append('g')
                .classed('tick-circles', true)
                .merge(tickCircleContainer);

            var tickCircles = tickCircleContainer
                .selectAll('circle')
                .data(this.tickCircleValues);

            tickCircles = tickCircles
                .enter()
                .append('circle')
                .style('fill', 'none')
                .attr('stroke', '#dddddd')
                .merge(tickCircles);

            var hideCircles = this.mode == "simple" || this.mode == "pdf" ||(this.quadrant || this.quadrant === 0);
            tickCircles
                .transition("animate")
                .ease(d3.easeExp)
                .duration(800)
                .attr('r', (d) => hideCircles ? 0 : this.barScale(d));

            // Container for the segments
            root.selectAll('g.segments').data([1]).enter().append('g').classed('segments', true)

            // Container for the labels
            root.selectAll('g.labels').data([1]).enter().append('g').classed('labels', true).append('defs');
        }

        private outerRadius(d: Part) {
            if (d) {
                return this.barScale(d.Visible ? d.Score : 0.01);
            } else {
                return 0.01;
            }
        }

        private startAngle(d: Part, i: number) {
            return ((i + 0) * 2 * Math.PI) / this.numBars;
        }
        private centerAngle(d: Part, i: number) {
            return ((i + 0.5) * 2 * Math.PI) / this.numBars;
        }
        private endAngle(d: Part, i: number) {
            return ((i + 1) * 2 * Math.PI) / this.numBars;
        }

        public setSimple(simple: boolean) {
            this.mode = simple ? "simple" : "normal";
            this.update();
        }

        public setQuadrant(quadrant: number) {
            // Hide unselected quadrants
            // Map site quadrant to d3 quadrant,
            // because site starts top-left and d3 top-right
            if (quadrant !== null && quadrant !== undefined) {
                switch (quadrant) {
                    case 0:
                        this.quadrant = 3;
                        break;
                    case 1:
                        this.quadrant = 0;
                        break;
                    case 2:
                        this.quadrant = 2;
                        break;
                    case 3:
                        this.quadrant = 1;
                        break;
                }
            } else {
                this.quadrant = null;
            }
            this.update();
        }

        private chart(data: Part[], transitionDuration?: number) {
            this.initData(data);
            this.initChart();

            var g = d3.select(this.root[0]).select('svg g.radial-barchart');

            // Segment enter/exit/update
            var segments = <any>d3.select(this.root[0])
                .select('g.segments')
                .selectAll('path')
                .data(data);

            segments.exit().remove();
            segments = segments
                .enter()
                .append('path')
                .on('mouseover', (d, i) => { this.hoverSegment = d; this.update(); })
                .on('mouseout', (d, i) => { this.hoverSegment = null; this.update(); })
                .attr('class', (d, i) => `segment-${i}`)
                .merge(segments);


            var arc = <any>d3.arc<Part>()
                .innerRadius(this.clearCenter).outerRadius(this.outerRadius.bind(this))
                .startAngle(this.startAngle.bind(this))
                .endAngle(this.endAngle.bind(this));

            segments
                .transition()
                .duration(transitionDuration || this.transitionDuration)
                .attr('d', arc);

            var labels = d3.select(this.root[0])
                .select('g.labels')
                .selectAll('text')
                .data(data);

            var textPath = d3.arc<Part>()
                .innerRadius(this.clearCenter + 20).outerRadius(this.barHeight)
                .startAngle(this.centerAngle.bind(this))
                .endAngle(this.centerAngle.bind(this));

            labels.exit().remove();

            labels.enter()
                .select("defs")
                .append("path")
                .attr("id", (d, i) => 'path' + i)
                .attr("href", "")
                .attr("stroke", "blue")
                .attr("fill", "none")
                .attr("stroke-width", 2)
                .attr('d', (d, i) => {
                    // Reverse start and end points
                    var raw = textPath(d, i);
                    var startLoc = /M(.*?)L/;
                    var endLoc = /L(.*?)Z/;
                    var newEnd = startLoc.exec(raw)[1];
                    var newStart = endLoc.exec(raw)[1];
                    raw = `M${newStart} L${newEnd}Z`;
                    return raw;
                });

            labels.enter()
                .append('text')
                .on('mouseover', (d, i) => { this.hoverSegment = d; this.update(); })
                .on('mouseout', (d, i) => { this.hoverSegment = null; this.update(); })
                .style("opacity", 0)
                .attr("dy", "0.3em")
                .append("textPath")
                //.attr("writing-mode", "tb")
                //.attr("href", (d, i) => window.location.href + "#path" + i)
                .attr("href", (d, i) => window.location.href + "#path" + i)
                .attr("xlink:href", (d, i) => window.location.href + "#path" + i)
                .attr("text-anchor", (d, i) => i >= this.numBars / 2 ? "end" : "start")
                .attr("startOffset", (d, i) => i >= this.numBars / 2 ? "100%" : "0%")
 
            // Reselect with newly created items included
            labels = d3.select(this.root[0])
                .select('g.labels')
                .selectAll('text')
                .data(data);

            // Set values
            labels.select("textPath")
                .attr("href", (d, i) => window.location.href + "#path" + i)
                .attr("xlink:href", (d, i) => window.location.href + "#path" + i)
                .text((d, i) => d.Name)

            //var nodes = labels.select("textPath").nodes();
            //nodes.forEach((element, index) => {
            //    var newAttr = document.createAttributeNS("http://www.w3.org/1999/xlink", "href");
            //    newAttr.value = "#path" + Math.round(Math.random() * 11);
            //    (<HTMLElement>element).attributes.setNamedItemNS(newAttr);
            //});

            // Hide immediate, show delayed
            labels.transition()
                .delay((d, i) => this.mode == "simple" || !d.Visible ? 0 : 1000)
                .duration(250)
                .ease(d3.easeLinear)
                .style("opacity", (d, i) => this.mode == "simple" || this.mode == "pdf" || !d.Visible ? 0 : 1);
                
            this.renderOverlays();
        }

        private renderOverlays() {
            var root = <any>d3.select(this.root[0]).select('svg g.radial-barchart');
            var g = root.selectAll('g.axis')
                .data([1]);

            // Spokes
            //g.enter().append('g')
            //    .classed('spokes', true)
            //    .selectAll('line')
            //    .data(this.keys)
            //    .enter()
            //    .append('line')
            //    .attr('y2', -this.barHeight)
            //    .attr('transform', (d, i) => this.svgRotate(i * 360 / this.numBars));

            // Axis
            var axisScale = d3.scaleLinear().domain(this.domain).range([-this.clearCenter, -this.barHeight]);
            var axis = d3.axisBottom(axisScale);
            if (this.tickValues)
                axis.tickValues(this.tickValues);
            axis.tickSize(-2);

           //  Outer circle
            g.enter().append('circle')
                .classed('selection', true)
                .attr('r', 0)
                .style('fill', 'none')
                .attr("stroke-width", 1)
                .style('stroke', '#000');

            var selection = d3.select(this.root[0]).select('svg g.radial-barchart circle.selection')
                .transition()
                .attr('r', this.outerRadius(this.hoverSegment))

            //var first = g.enter().nodes().length;

            g = g.enter().append('g')
                .style("opacity", 0)
                .classed('axis', true)
                .call(axis)
                .attr('font-size', '9')
                .merge(g);


            var hideAxis = this.mode != "normal" || (this.quadrant > 0 || this.quadrant === 0);
            
            g.transition()
                .delay((d, i) => hideAxis ? 0 : 1000)
                .duration(250)
                .ease(d3.easeLinear)
                .style("opacity", (d, i) => hideAxis ? 0 : 1)

            g.selectAll(".tick line")
                .attr("stroke", "#dddddd");
            g.selectAll(".tick text")
                .attr("fill", "#999999");
            g.selectAll(".domain")
                .attr("stroke", "#dddddd");

        }

    }
}