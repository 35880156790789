/// <reference types="jquery" />
/// <reference types="highcharts" />
/// <reference path="baseChart.ts" />

module Facetbase.Chart {

    export class SimpleChart extends BaseChart {

        private chart: HighChartWrapper;

        constructor(private root: JQuery, private config: FacetbaseChart, private inPdf: boolean, private silentFail: boolean, private language: string)
        {
            super(language);

            var chartOptions: Highcharts.Options;

            var dataLabels: Highcharts.DataLabels = {
                enabled: this.config.layout.showDataLabels,
                color: "#000",
                format: `${this.config.layout.dataLabelValuePrefix}{y:,.${this.config.layout.dataLabelDecimalPrecision}f} ${this.config.layout.dataLabelValueSuffix}`
            }

            switch (this.config.type) {
                case "bar":
                    chartOptions = ChartOptions.Bar();
                    chartOptions.plotOptions.bar = <Highcharts.BarChart>{
                        dataLabels: dataLabels
                    }
                    break;
                case "column":
                    chartOptions = ChartOptions.Column();
                    chartOptions.plotOptions.column = <Highcharts.ColumnChart>{
                        dataLabels: dataLabels
                    }
                    break;
                case "line":
                    chartOptions = ChartOptions.Line();
                    chartOptions.plotOptions.line = <Highcharts.LineChart>{
                        dataLabels: dataLabels,
                        marker: {
                            enabled: false
                        }
                    }
                    break;
                case "spline":
                    chartOptions = ChartOptions.Spline();
                    chartOptions.plotOptions.line = <Highcharts.LineChart>{
                        dataLabels: dataLabels,
                        marker: {
                            enabled: false
                        }
                    }
                    break;
                case "stackedbar":
                    chartOptions = ChartOptions.StackedBar();
                    chartOptions.plotOptions.bar = <Highcharts.BarChart>{
                        stacking: "normal",
                        dataLabels: dataLabels
                    }
                    var yAxis = (<Highcharts.AxisOptions>chartOptions.yAxis);
                    yAxis.stackLabels = { enabled: this.config.layout.showStackLabels };
                    break;
                case "stackedcolumn":
                    chartOptions = ChartOptions.StackedColumn();
                    chartOptions.plotOptions.column = <Highcharts.ColumnChart>{
                        stacking: "normal",
                        dataLabels: dataLabels
                    }
                    var yAxis = (<Highcharts.AxisOptions>chartOptions.yAxis);
                    yAxis.stackLabels = { enabled: this.config.layout.showStackLabels };
                    break;
                case "stackedarea":
                    chartOptions = ChartOptions.StackedArea();
                    chartOptions.plotOptions.area = <Highcharts.AreaChart>{
                        stacking: "normal",
                        dataLabels: dataLabels
                    }
                    var yAxis = (<Highcharts.AxisOptions>chartOptions.yAxis);
                    yAxis.stackLabels = { enabled: this.config.layout.showStackLabels };
                    break;
            }

            // enable animation?
            chartOptions.chart.animation = !this.inPdf;

             // Apply layout
            if (this.config.layout) {
                // title
                this.configTitle(chartOptions.title, this.config.layout);

                // exporting
                this.configExporting(chartOptions.exporting, this.config.data[0].id, this.inPdf)

                // subtitle
                this.configSubtitle(chartOptions.subtitle, this.config.layout);

                // Colors
                this.configColors(chartOptions.colors, this.config.layout);

                // xAxis                        
                this.configXAxis(<Highcharts.AxisOptions>chartOptions.xAxis, this.config.layout);

                // yAxis                                              
                this.configYAxis(<Highcharts.AxisOptions>chartOptions.yAxis, this.config.layout);

                chartOptions.tooltip.useHTML = true;
                chartOptions.tooltip.pointFormat = '<span style="color:{point.color}">{series.name}</span>: <b>' + `${this.config.layout.dataLabelValuePrefix}{point.y:,.${this.config.layout.dataLabelDecimalPrecision}f} ${this.config.layout.dataLabelValueSuffix}` + "</b>";
            }




            this.chart = new HighChartWrapper(this.root, chartOptions);
            if (this.chart && this.config.data.length > 0) {

                // data
                var series: Array<Highcharts.IndividualSeriesOptions> = [];
                // columns
                var columnNames: Array<string> = [];
                for (var col of this.config.data[0].columns) {
                    columnNames.push(col.name);
                }


                this.chart.updateCategories(0, columnNames);
                // rows
                for (var row of this.config.data[0].rows) {
                    var rowData: Highcharts.IndividualSeriesOptions = {
                        id: `dd-${row.id}`,
                        name: row.name,
                        data: row.data.map(row => {
                            var point: Highcharts.DataPoint = {
                                y: <number>row
                            }
                            return point;
                        })
                    }
                    series.push(rowData);
                };

                // apply
                this.chart.addOrUpdateSeries(series);
            }

        }

    }

}