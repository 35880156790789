/// <reference types="jquery" />
/// <reference types="highcharts" />
/// <reference path="baseChart.ts" />

module Facetbase.Chart {

    export class DualColumnLineChart extends BaseChart {

        private chart: HighChartWrapper;

        constructor(private root: JQuery, private config: FacetbaseChart, private inPdf: boolean, private silentFail: boolean, private language: string)
        {
            super(language);

            var chartOptions: Highcharts.Options;

            var dataLabels: Highcharts.DataLabels = {
                enabled: this.config.layout.showDataLabels,
                color: "#000",
                format: `${this.config.layout.dataLabelValuePrefix}{y:,.${this.config.layout.dataLabelDecimalPrecision}f} ${this.config.layout.dataLabelValueSuffix}`
            }

            var dataLabels2: Highcharts.DataLabels = {
                enabled: this.config.layout.showDataLabels,
                format: `${this.config.layout.dataLabel2ValuePrefix}{y:,.${this.config.layout.dataLabel2DecimalPrecision}f} ${this.config.layout.dataLabel2ValueSuffix}`
            }

            chartOptions = ChartOptions.DualLineColumn();
            chartOptions.plotOptions.column = <Highcharts.ColumnChart>{
                dataLabels: dataLabels
            }
            chartOptions.plotOptions.spline = <Highcharts.SplineChart>{
                dataLabels: dataLabels2,
                marker: {
                    enabled: false
                }
            }

            // enable animation?
            chartOptions.chart.animation = !this.inPdf;

            // Apply layout
            if (this.config.layout) {
                // title
                this.configTitle(chartOptions.title, this.config.layout);

                // exporting
                this.configExporting(chartOptions.exporting, this.config.data[0].id, this.inPdf)

                // subtitle
                this.configSubtitle(chartOptions.subtitle, this.config.layout);

                // Colors
                this.configColors(chartOptions.colors, this.config.layout);

                // xAxis                        
                this.configXAxis(<Highcharts.AxisOptions>chartOptions.xAxis, this.config.layout);

                // yAxis                                              
                this.configYAxis(<Highcharts.AxisOptions>chartOptions.yAxis[0], this.config.layout);

                if (this.config.layout.y2AxisShow) {
                    // Render second axis
                    this.configYAxis2(<Highcharts.AxisOptions>chartOptions.yAxis[1], this.config.layout);
                } else {
                    (<Highcharts.AxisOptions[]>chartOptions.yAxis).splice(0, 1);
                }


                chartOptions.tooltip.useHTML = true;
                chartOptions.tooltip.shared = true;
                //chartOptions.tooltip.pointFormat = '<span style="color:{point.color}">{series.name}</span>: <b>' + `${this.config.layout.dataLabelValuePrefix}{point.y:,.${this.config.layout.dataLabelDecimalPrecision}f} ${this.config.layout.dataLabelValueSuffix}` + "</b>";
            }


            this.chart = new HighChartWrapper(this.root, chartOptions);
            if (this.chart && this.config.data.length > 1) {

                // data
                var series: Array<Highcharts.IndividualSeriesOptions> = [];
                // columns
                var columnNames: Array<string> = [];
                for (var col of this.config.data[0].columns) {
                    columnNames.push(col.name);
                }


                this.chart.updateCategories(0, columnNames);

                // small check to see which group is which...
                var intLinesIndex = 0;
                var intTrendsIndex = 1;
                if (this.config.data[0].yGroup == "series2") {
                    intLinesIndex = 1;
                    intTrendsIndex = 0;
                };


                // rows
                for (var row of this.config.data[intLinesIndex].rows) {

                    var columnRowData: Highcharts.ColumnChartSeriesOptions = {
                        type: 'column',
                        id: `dd-${row.id}`,
                        name: row.name,
                        data: row.data.map(row => {
                            var point: Highcharts.DataPoint = {
                                y: <number>row
                            }
                            return point;
                        }),
                        tooltip: { pointFormat: '<span style="color:{point.color}">{series.name}</span>: <b>' + `${this.config.layout.dataLabelValuePrefix}{point.y:,.${this.config.layout.dataLabelDecimalPrecision}f} ${this.config.layout.dataLabelValueSuffix}` + "</b><br/>" }
                    }
                    series.push(columnRowData);
                };
                for (var row of this.config.data[intTrendsIndex].rows) {
                    var splineRowData: Highcharts.SplineChartSeriesOptions = {
                        type: 'spline',
                        yAxis: this.config.layout.y2AxisShow ? 1 : 0,
                        id: `dd-${row.id}`,
                        name: row.name,
                        data: row.data.map(row => {
                            var point: Highcharts.DataPoint = {
                                y: <number>row
                            }
                            return point;
                        }),
                        tooltip: { pointFormat: '<span style="color:{point.color}">{series.name}</span>: <b>' + `${this.config.layout.dataLabel2ValuePrefix}{point.y:,.${this.config.layout.dataLabel2DecimalPrecision}f} ${this.config.layout.dataLabel2ValueSuffix}` + "</b><br/>" }
                    }
                    series.push(splineRowData);
                };

                // apply
                this.chart.addOrUpdateSeries(series);
            }
        }

    }

}