interface FacetbaseChart {
    type: string;
    layout: ChartLayout;
    data: ChartMatrix[];
}

interface ChartLayout {
    title: string;
    subtitle: string;
    colors: string;
    altColors: string;

    xAxisTitle: string;
    xAxisMin: number;
    xAxisMax: number;
    xAxisTickInterval: number;

    yAxisTitle: string;
    yAxisMin: number;
    yAxisMax: number;
    yAxisTickInterval: number;

    y2AxisShow: boolean;
    y2AxisTitle: string;
    y2AxisMin: number;
    y2AxisMax: number;
    y2AxisTickInterval: number;

    axisLabelDecimalPrecision: number;
    axisLabelValuePrefix: string;
    axisLabelValueSuffix: string;

    axis2LabelDecimalPrecision: number;
    axis2LabelValuePrefix: string;
    axis2LabelValueSuffix: string;

    dataLabelDecimalPrecision: number;
    dataLabelValuePrefix: string;
    dataLabelValueSuffix: string;

    dataLabel2DecimalPrecision: number;
    dataLabel2ValuePrefix: string;
    dataLabel2ValueSuffix: string;

    showDataLabels: boolean;
    showStackLabels: boolean;
}


interface ChartMatrix {
    id: string;
    xGroup: string;
    yGroup: string;
    columns: Array<ChartColumn>;
    rows: Array<ChartRow>;
}

interface ChartColumn {
    id: string;
    name: string;
}

interface ChartRow {
    id: string;
    name: string;
    data: any[];
}
