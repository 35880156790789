/// <reference types="jquery" />
/// <reference types="highcharts" />
/// <reference path="highchartWrapper.ts" />
/// <reference path="../data.ts" />


module Facetbase.Chart {
    
    export class BaseChart{


        constructor(language: string) {
            this.InitHighcharts(language);

        }

        InitHighcharts(language: string) {
            if (language == "nl") {
                Highcharts.setOptions({
                    lang: {
                        loading: 'Wordt geladen...',
                        months: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
                        weekdays: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
                        shortMonths: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
                        downloadPNG: 'Download als PNG',
                        downloadJPEG: 'Download als JPEG',
                        downloadPDF: 'Download als PDF',
                        downloadSVG: 'Download als SVG',
                        resetZoom: 'Reset',
                        resetZoomTitle: 'Reset',
                        thousandsSep: '.',
                        decimalPoint: ','
                    }
                });
            } else {
                // Highcharts Default (English)
                Highcharts.setOptions({
                    lang: {
                        thousandsSep: ','
                    }
                });
            }

        }


        configTitle(title: Highcharts.TitleOptions, layout: ChartLayout) {
            if (layout.title && layout.title.length > 0) {
                title.text = layout.title;
            }
        }


        configExporting(exporting: Highcharts.ExportingOptions, fileName: string, inPdf: boolean) {
            exporting.filename = fileName;
            (<any>exporting.buttons.contextButton.menuItems) = ['printChart', 'separator', 'downloadPNG', 'downloadSVG', 'separator', { textKey: 'downloadCSV', onclick: function () { this.downloadCSV(); } }];

            if (inPdf) {
                exporting.enabled = false;
                exporting.buttons.contextButton.enabled = false;
            }
        }

        configSubtitle(subtitle: Highcharts.SubtitleOptions, layout: ChartLayout) {
            if (layout.subtitle && layout.subtitle.length > 0) {
                subtitle.text = layout.subtitle;
            }
        }

        configColors(colors: Highcharts.Color[], layout: ChartLayout) {

            var layoutColors: string[] = [];

            if (layout.altColors && layout.altColors.length > 0) {
                layoutColors = layout.altColors.split(" ");
            }
            else if (layout.colors && layout.colors.length > 0) {
                layoutColors = layout.colors.split(" ");
            }

            for (var color of layoutColors) {
                colors.push(color);
            }

        }

        configXAxis(axis: Highcharts.AxisOptions, layout: ChartLayout) {

            if (layout.xAxisMin) { axis.min = layout.xAxisMin };
            if (layout.xAxisMax) { axis.max = layout.xAxisMax };
            if (layout.xAxisTickInterval) { axis.tickInterval = layout.xAxisTickInterval };
            this.configXAxisTitle(axis.title, layout);
        }

        configXAxisTitle(title: Highcharts.AxisTitle, layout: ChartLayout) {
            if (layout.xAxisTitle && layout.xAxisTitle.length > 0) {
                title.text = layout.xAxisTitle;
            }
        }

        configYAxis(axis: Highcharts.AxisOptions, layout: ChartLayout) {
            if (layout.yAxisMin) { axis.min = layout.yAxisMin };
            if (layout.yAxisMax) { axis.max = layout.yAxisMax };
            if (layout.yAxisTickInterval) { axis.tickInterval = layout.yAxisTickInterval };
            axis.labels.format = `${layout.axisLabelValuePrefix}{value:,.${layout.axisLabelDecimalPrecision}f} ${layout.axisLabelValueSuffix}`;
            this.configYAxisTitle(axis.title, layout);
        }

        configYAxisTitle(title: Highcharts.AxisTitle, layout: ChartLayout) {
            if (layout.yAxisTitle && layout.yAxisTitle.length > 0) {
                title.text = layout.yAxisTitle;
            }
        }


        configYAxis2(axis: Highcharts.AxisOptions, layout: ChartLayout) {
            if (layout.y2AxisMin) { axis.min = layout.y2AxisMin };
            if (layout.y2AxisMax) { axis.max = layout.y2AxisMax };
            if (layout.y2AxisTickInterval) { axis.tickInterval = layout.y2AxisTickInterval };
            axis.labels.format = `${layout.axis2LabelValuePrefix}{value:,.${layout.axis2LabelDecimalPrecision}f} ${layout.axis2LabelValueSuffix}`;
            this.configYAxis2Title(axis.title, layout);
        }

        configYAxis2Title(title: Highcharts.AxisTitle, layout: ChartLayout) {
            if (layout.y2AxisTitle && layout.y2AxisTitle.length > 0) {
                title.text = layout.y2AxisTitle;
            }
        }

    }
}