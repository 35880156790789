/// <reference path="ajax.ts" />
/// <reference path="renderer.ts" />



// override editor renderer
if ((<any>window).Facetbase) {
    if ((<any>window).Facetbase.Management) {
        (<any>window).Facetbase.Management.ChartRenderer = function (jqChartContainer, jqChartLoader) {
            return new Facetbase.Chart.EditorRenderer(jqChartContainer, jqChartLoader);
        }
    }
}


interface iEditorRenderer {
    btSilentFail: boolean;
    btDoAnimation: boolean;

    fnRenderChartAjax(chartAlias: string);
    fnDeleteChartPreview();
    fnFinishRenderJson(result: any);
}

// Support rendering inside Facetbase backend by wrapping some functions
module Facetbase.Chart {
    export class EditorRenderer implements iEditorRenderer{

        public btSilentFail: boolean;
        public btDoAnimation: boolean;

        private jqRoot: JQuery;
        private jqLoader: JQuery;
        private chartRenderer: Facetbase.Chart.Renderer;

        constructor(chart: JQuery, loader: JQuery) {
            this.jqRoot = chart;
            this.jqLoader = loader;

            //this.jqLoader = loader;
            var language: string = $('html').attr('lang');
            //var strPublication: string = chart.data('publication');

            //if (strPublication && strPublication == "kpi") {

            //}

            this.chartRenderer = new Facetbase.Chart.Renderer(chart, true, false, language);
        }   

        public fnRenderChartAjax(chartAlias: string) {

            if (chartAlias) {

                this.fnDeleteChartPreview();

                // Show loader
                if (this.jqLoader) {
                    this.jqLoader.show();
                }

                var ajax = new Ajax();

                // Call to server to render chart JSON
                ajax.renderChart(
                    chartAlias,
                    this.fnFinishRenderJson.bind(this));


                // Cancel further navigation
                return false;
            }

        }
         
        public fnDeleteChartPreview() {
            this.jqRoot.empty();
        }

        public fnFinishRenderJson(result: any) {
            this.chartRenderer.loadData(result)
            this.chartRenderer.render();

            if (this.jqLoader) { this.jqLoader.hide();}        
        }             
    }




}




