/// <reference types="jquery" />
/// <reference types="highcharts" />
/// <reference path="../highchart/baseChart.ts" />
/// <reference path="../d3/radial.ts" />

module Facetbase.Chart {

    interface HMI extends Facetbase.Chart.D3.HMI { }

    export interface Serie {
        name: string,
        type: string,
        data: Array<number>
    }

    export class TrendGraph {

        private root: JQuery;
        private data: Array<HMI>;
        private graphType: string;

        public publications: Array<string>;
        public series: Array<Serie>;
        private responsiveConfig = {};
        private chartHeight = null;
        public fontSize: string;

        constructor(root: JQuery, data?: JSON, mode?: string) {
            this.root = root;

            //set graph type
            this.graphType = root.attr('data-type');

            //set options
            this.setOptions(mode);

            //get data
            if (data) {
                this.dataAvailable(data);
            } else {
                $.ajax('/api/hmi/trend').then((data) => this.dataAvailable(data));  //call gebeurt 5 keer
            }            
        }



        private setOptions(mode) {
            //PDF heights also in _trend.scss, because of pdfreactor problems
            if (mode == 'pdf') {
                this.fontSize = '9pt';
                this.chartHeight = 400;
            }
            else if (mode == 'pdf-small') {
                this.fontSize = '9pt';
                this.chartHeight = 230;
            }
            else {
                this.fontSize = '15px';
                this.responsiveConfig = {
                    rules: [{
                        condition: {
                            maxWidth: 800
                        },
                        chartOptions: {
                            legend: {
                                align: 'left',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                            }
                        }
                    }]
                };
            }
        }

        private dataAvailable(data) {
            this.data = data;
            //console.log(data);
            this.publications = this.getPublications();
            this.series = this.makeSeries();
            this.setTitle();
            this.initGraph(this.root[0]);
        }


        //data functions
        private getPublications() {
            var listOfPublications = [];
            for (var i: number = 0; i < this.data.length; i++) {
                var hmi = this.data[i];
                listOfPublications.push(hmi.Updated);
            }
            return listOfPublications;
        }

        private setTitle() {
            var title = $('.trend-graph-page-title');
            var start = this.publications[0];
            var end = this.publications[this.publications.length - 1];
            title.text(start + " - " + end);
        }

        private getTrendline() {
            var trendline = [];
            for (var i: number = 0; i < this.data.length; i++) {
                var hmi = this.data[i];
                if (this.graphType == 'full') {
                    trendline.push(hmi.Score);
                }
                else {
                    trendline.push(hmi.Categories[Number(this.graphType)].Score);
                }
            }
            return trendline;
        }

        private getColumnData(index: number) {
            var columns = [];
            for (var i: number = 0; i < this.data.length; i++) {
                var hmi: HMI = this.data[i];
                if (this.graphType == 'full') {
                    var score = hmi.Categories[index].Score;
                }
                else {
                    var score = hmi.Categories[Number(this.graphType)].Parts[index].Score;
                }
                columns.push(score);
            }
            return columns;
        }


        //make series
        private makeSeries() {

            var hmi: HMI = this.data[0];
            var series: Array<Serie> = [];

            var columns = [];

            if (this.graphType == 'full') {
                columns = hmi.Categories;
            }
            else {
                columns = hmi.Categories[Number(this.graphType)].Parts;
            }

            for (var i: number = 0; i < columns.length; i++) {
                var column = columns[i];

                //get data by category index
                var data = this.getColumnData(i);

                var serie: Serie = {
                    name: column.Name,
                    type: 'column',
                    data: data
                };

                series.push(serie);
            }

            var trendline: Serie = {
                name: this.getTrendlineName(),
                type: 'spline',
                data: this.getTrendline()
            };

            series.push(trendline);

            return series;
        }

        getColors() {
            var colors = [];
            switch (this.graphType) {
                case 'full':
                    colors = ['#0096d2', '#37a432', '#00959c', '#ec9600', '#005935'];
                    break;
                case '0':
                    colors = ['#f49b00', '#f6af33', '#f8c366', '#005935'];
                    break;
                case '1':
                    colors = ['#009bdb', '#33afe2', '#66c3e9', '#005935'];
                    break;
                case '2':
                    colors = ['#009aa3', '#33aeb5', '#66c2c8', '#005935'];
                    break;
                case '3':
                    colors = ['#39a935', '#61ba5d', '#88cb86', '#005935'];
                    break;
            }
            return colors;
        }

        getTrendlineName() {
            var name = 'Housing Market Indicator';
            if (this.graphType !== 'full') {
                name = this.data[0].Categories[Number(this.graphType)].Name + ' average';
            }
            return name;
        }


        //make graph
        initGraph(elem: HTMLElement) {
            let chart = Highcharts.chart(elem, {

                chart: {
                    style: {
                        fontFamily: 'Mark Pro',
                        fontSize: '16px'
                    },
                    height: this.chartHeight
                },

                title: {
                    text: ''
                },

                credits: {
                    enabled: false
                },

                colors: this.getColors(),

                xAxis: {
                    categories: this.publications,
                    crosshair: true
                },

                yAxis: {
                    max: 10,
                    title: {
                        text: ''
                    }
                },

                tooltip: {
                    shared: true
                },
                
                legend: {
                    itemStyle: {
                        fontSize: this.fontSize,
                        fontWeight: '400'
                    },
                    align: 'right',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    itemMarginTop: 10,
                    x: 0,
                    y: 20,
                    symbolWidth: 14,
                    symbolRadius: 0
                },

                series: this.series,

                // MOBILE CHARTS
                responsive: this.responsiveConfig
            });
        }
    }
}