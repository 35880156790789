/// <reference types="jquery" />
/// <reference types="highcharts" />


module Facetbase.Chart {

    class CancelTimeout {
        options: Highcharts.IndividualSeriesOptions;
        id: number;
    }

    export class HighChartWrapper {

        chartObject: Highcharts.ChartObject;
        level: number = 0;
        allSeries: Array<Highcharts.IndividualSeriesOptions> = [];
        allDrilldowns: Array<Highcharts.IndividualSeriesOptions> = [];
        static fixed = false;

        constructor(private root: JQuery, options: Highcharts.Options) {
            options.chart.renderTo = this.root[0];
            options.chart.events.drilldown = (e) => { this.level += 1 }
            options.chart.events.drillup = (e) => { this.level -= 1 }
            this.chartObject = new Highcharts.Chart(options);

            if (!HighChartWrapper.fixed) {
                (<any>Highcharts).Point.prototype.doDrilldown = this.drillDownHack();
                HighChartWrapper.fixed = true;
            }
        }

        public updateTitle(title: string, subtitle?: string) {
            this.chartObject.setTitle({ text: title }, { text: subtitle });
        }

        public updateCategories(number: number, categories: string[]) {
            this.chartObject.xAxis[number].setCategories(categories);
        }

        public addOrUpdateSeries(series: Highcharts.IndividualSeriesOptions | Highcharts.IndividualSeriesOptions[]) {
            if (series instanceof Array) {
                for (var serie of series) {
                    this.showSeries(serie);
                }
                this.removeExtraSeries(series);
                this.chartObject.redraw();
            } else {
                this.showSeries(series);
            }
        }

        private removeExtraSeries(series: Highcharts.IndividualSeriesOptions[]) {
            for (var extra of this.allSeries) {
                if (series.every(x => !this.matchSeriesOptions(x, extra))) {
                    var visibleSeries = this.matchSeries(extra);
                    if (visibleSeries) {
                        visibleSeries.hide();
                    } else {
                        extra.visible = false;
                        this.clearSeries(extra, false);
                    }
                }
            }
        }

        private showSeries(series: Highcharts.IndividualSeriesOptions) {
            var visible = this.matchSeries(series);
            if (visible) {
                visible.show();
            } else {
                series.visible = true;
            }
            this.addOrUpdateSeriesData(series, false);
            this.cancelClearSeries(series);
        }

        private timeouts: Array<CancelTimeout> = [];
        private getTimeout(series: Highcharts.IndividualSeriesOptions) {
            for (var i = 0; i < this.timeouts.length; i++) {
                var waiting = this.timeouts[i];
                if (this.matchSeriesOptions(series, waiting.options)) {
                    return waiting;
                }
            }
        }
        private removeTimeout(series: Highcharts.IndividualSeriesOptions) {
            for (var i = this.timeouts.length - 1; i >= 0; i--) {
                var waiting = this.timeouts[i];
                if (this.matchSeriesOptions(series, waiting.options)) {
                    this.timeouts = this.timeouts.splice(i, 1);
                }
            }
        }

        private setClearSeries(series: Highcharts.IndividualSeriesOptions) {
            var timeout = this.getTimeout(series);
            if (timeout) {
                clearTimeout(timeout.id);
            } else {
                timeout = new CancelTimeout();
                timeout.options = series;
                this.timeouts.push(timeout);
            }
            timeout.id = <any>setTimeout(this.clearSeries.bind(this, series, true), 500);
        }

        private cancelClearSeries(series: Highcharts.IndividualSeriesOptions) {
            var timeout = this.getTimeout(series);
            if (timeout) {
                clearTimeout(timeout.id);
                this.removeTimeout(series);
            }
        }

        private clearSeries(series: Highcharts.IndividualSeriesOptions, delayed: boolean) {
            (<Highcharts.DataPoint[]>series.data).map(d => d.y = 0);
            // console.log(`clear series (${delayed}) id: ${series.id}, name: ${series.name}`);

            this.addOrUpdateSeriesData(series, false);
            if (delayed) {
                this.removeTimeout(series);
            }
        }

        public addOrUpdateDrillDowns(series: Highcharts.IndividualSeriesOptions | Highcharts.IndividualSeriesOptions[]) {
            if (series instanceof Array) {
                for (var serie of series) {
                    this.addOrUpdateSeriesData(serie, true);
                }
            } else {
                this.addOrUpdateSeriesData(series, true);
            }
        }


        private addOrUpdateSeriesData(seriesOptions: Highcharts.IndividualSeriesOptions, drill: boolean) {
            var visible = this.matchSeries(seriesOptions);
            if (visible) {
                //console.log(`update visible series id: ${seriesOptions.id}, name: ${seriesOptions.name}`);
                this.updateSeries(visible, seriesOptions);
            } else {
                var match = false;
                var seriesToMatch = drill ? this.allDrilldowns : this.allSeries;
                for (var serie of seriesToMatch) {
                    if (this.matchSeriesOptions(seriesOptions, serie)) {
                        //console.log(`update invisible series id: ${seriesOptions.id}, name: ${seriesOptions.name}`);
                        this.updateSeriesOptions(serie, seriesOptions);
                        match = true;
                    }
                }
                if (match) {
                    var array = (<any>this.chartObject).drilldownLevels;
                    if (array) {
                        for (var s of (<any>this.chartObject).drilldownLevels) {
                            for (var o of s.levelSeriesOptions) {
                                if (this.matchSeriesOptions(seriesOptions, o)) {
                                    //console.log(`update drilldown level id: ${seriesOptions.id}, name: ${seriesOptions.name}`);
                                    this.updateSeriesOptions(o, seriesOptions);
                                }
                            }
                        }
                    }
                } else {
                    if (drill) {
                        this.chartObject.options.drilldown.series.push(seriesOptions);
                        this.allDrilldowns.push(seriesOptions);
                    } else {
                        if (this.level == 0) {
                            //console.log(`add series id: ${seriesOptions.id}, name: ${seriesOptions.name} as regular`);
                            this.chartObject.addSeries(seriesOptions, true, true);
                            this.allSeries.push(seriesOptions);
                        } else {
                            console.error(`not possible to add new series while drilled down`);
                        }
                    }
                }
            }
        }

        private updateSeriesOptions(a: Highcharts.IndividualSeriesOptions, b: Highcharts.IndividualSeriesOptions) {
            a.data = b.data;
            a.visible = b.visible;
            a.name = b.name;
        }

        private updateSeries(a: Highcharts.SeriesObject, b: Highcharts.IndividualSeriesOptions) {
            // Todo, figure out correct typing
            a.setData(<any>b.data);
            a.name = b.name;
            this.updateSeriesOptions(a.options, b);
        }

        private matchSeries(seriesOptions: Highcharts.IndividualSeriesOptions): Highcharts.SeriesObject {
            for (var hcs of this.chartObject.series) {
                if (this.matchSeriesOptions(hcs.options, seriesOptions)) {
                    return hcs;
                }
            }
        }

        private matchSeriesOptions(a: Highcharts.IndividualSeriesOptions, b: Highcharts.IndividualSeriesOptions) {
            if (a.id || b.id) {
                if (a.id == b.id) {
                    return true;
                }
            } else {
                if (a.name == b.name) {
                    return true;
                }
            }
            return false;
        }

        private drillDownHack() {
            return function (
                _holdRedraw: boolean,
                category: any,
                originalEvent: Highcharts.ChartDrilldownEvent) {

                var series = this.series,
                    chart = series.chart,
                    drilldown = chart.options.drilldown;

                if (!chart.ddDupes) {
                    chart.ddDupes = [];
                }

                if (!(this.drilldown instanceof Array)) {
                    this.drilldown = [this.drilldown];
                }

                for (var di = 0; di < this.drilldown.length; di++) {
                    var dd = this.drilldown[di];
                    var i = (drilldown.series || []).length;
                    var seriesOptions = null;
                    while (i-- && !seriesOptions) {
                        if (drilldown.series[i].id === dd && (<any>Highcharts).inArray(dd, chart.ddDupes) === -1) {
                            seriesOptions = drilldown.series[i];
                            chart.ddDupes.push(dd);
                        }
                    }

                    // Fire the event. If seriesOptions is undefined, the implementer can check for 
                    // seriesOptions, and call addSeriesAsDrilldown async if necessary.
                    (<any>Highcharts).fireEvent(chart, 'drilldown', {
                        point: this,
                        seriesOptions: seriesOptions,
                        category: category,
                        originalEvent: originalEvent,
                        points: category !== undefined && this.series.xAxis.getDDPoints(category).slice(0)
                    }, function (e) {
                        var chart = e.point.series && e.point.series.chart,
                            seriesOptions = e.seriesOptions;
                        if (chart && seriesOptions) {
                            chart.addSingleSeriesAsDrilldown(e.point, seriesOptions);
                        }
                    });
                }

                if (!_holdRedraw) {
                    chart.applyDrilldown();
                }
            }
        }
    }

    export class ChartOptions {


        private static Common(): Highcharts.Options {
            var out: Highcharts.Options = {           
                chart: {
                    events: {},
                    spacingTop: 25
                },
                exporting: {
                    width: 800,
                    sourceWidth: 800,
                    filename: "",
                    buttons: {
                        contextButton: {
                            align: "right",
                            y: -25
                        }
                    }
                },
                colors: [],
                title: {
                    text: ""
                    ,
                    style: {
                        fontSize: "20px",
                        fontFamily: "'Verdana', Arial, sans-serif"
                    }
                },
                subtitle: {
                    text: ""
                },

                tooltip: { pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f}</b><br/>' },
                plotOptions: {
                    series: {
                        dataLabels: {
                            allowOverlap: true,
                            crop: false,
                            overflow: "none",
                            style: {
                                fontFamily: "'Verdana', Arial, sans-serif",
                                fontSize: "10px",
                                fontWeight: "normal"
                            }
                        }
                    }
                },
                series: [],
                legend: {
                    enabled: true,
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                    borderWidth: 0,
                    itemStyle: {
                        fontWeight: "normal",
                        fontFamily: "'Verdana', Arial, sans-serif",
                        fontSize: "10px"
                    }
                },
                credits: { enabled: false }
            }

            return out;
        }


        private static CommonTwoAxis(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.Common(), {
                xAxis: {
                    title: {
                        text: ""
                        ,                        
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    labels: {                        
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    minorTickLength: 0,
                    categories: []
                },
                yAxis: {
                    title: {
                        text: ""
                        ,                        
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    labels: {                        
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    //plotLines: [{ value: 0, width: 1 }],
                    //gridLineWidth: 0,
                    //lineWidth: 0,
                    //tickWidth: 0,
                    min: 0
                }
            });
        }

        static Merge(base: Highcharts.Options, specific: Highcharts.Options): Highcharts.Options {
            var out = $.extend(true, base, specific);
            return out;
        }

        static Bar(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.CommonTwoAxis(), {
                chart: {
                    type: "bar"
                }
            });
        }


        static Column(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.CommonTwoAxis(), {
                chart: {
                    type: "column"
                }
            });
        }



        static CommonStacked(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.CommonTwoAxis(), {
                plotOptions: {
                    column: {
                        stacking: "normal"
                    }
                },
                yAxis: {
                    stackLabels: { enabled: true }
                }
            });
        }

        static StackedArea(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.CommonStacked(), {
                chart: {
                    type: "area"
                }
            });
        }

        static StackedBar(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.CommonStacked(), {
                chart: {
                    type: "bar"
                },
                yAxis: {
                    opposite: true
                },
                legend: {
                    reversed: true
                }
            });
        }

        static StackedColumn(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.CommonStacked(), {
                chart: {
                    type: "column"
                }
            });
        }

        static Pie(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.Common(), {
                chart: { type: 'pie', plotBorderWidth: null, plotShadow: false },
                plotOptions: {
                    pie: {
                        cursor: "pointer",
                        showInLegend: true
                    }
                },
                series: [
                    <Highcharts.PieChartSeriesOptions>{
                        type: "pie",
                        data: [],
                        dataLabels: {
                            enabled: true,
                            distance: -30
                        }
                    }
                ]
            });
        }

        static Line(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.CommonTwoAxis(), {
                chart: {
                    type: "line"
                }                
            });
        }


        static Spline(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.CommonTwoAxis(), {
                chart: {
                    type: "spline"
                },
                xAxis: {
                    labels: {
                        rotation: 90
                    }
                }

            });
        }


        static DualLineColumn(): Highcharts.Options {
            return ChartOptions.Merge(ChartOptions.Common(), {
                chart: {
                    type: "spline"
                },
                xAxis: {
                    title: {
                        text: ""
                        ,
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    labels: {
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    minorTickLength: 0,
                    categories: []
                },
                yAxis: [{
                    title: {
                        text: ""
                        ,
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    labels: {                        
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    min: 0
                },
                {
                    title: {
                        text: ""
                        ,
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    labels: {
                        style: {
                            color: "#000",
                            fontFamily: "'Verdana', Arial, sans-serif",
                            fontSize: "10px"
                        }
                    },
                    opposite: true,
                    plotLines: [{
                        value: 0,
                        width: 1
                    }]
                }
                ]
            })
        }
    }
}