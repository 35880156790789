/// <reference path="renderer.ts" />

module Facetbase.Chart {
    export class Plugin {

        private jqCharts: JQuery;

        private inPdf: boolean;
        private language: string;

        constructor() {         
            this.inPdf = $('.pdf-print').length > 0;
            this.language = $('html').attr('lang');

            // try to find charts
            this.jqCharts = $(".jqChartPlugin");

            if (this.jqCharts.length > 0) {
                this.jqCharts.each(this.renderChart.bind(this));
            }
        }

        renderChart(index: number, elem: Element) {
            var strPublication: string = jQuery(elem).data('publication');
            var jsonData:string = jQuery(elem).data('chart'); 

            //if (strPublication && strPublication == "speciaal") {
                // Custom renderer (use other libraries or styles?)
                // var chartRenderer = new Facetbase.Frontend.ChartRenderer(jQuery(value), null);
                
            //} 

            // regular renderer
            var chartRenderer = new Renderer(jQuery(<HTMLElement>elem), !this.inPdf, true, this.language);
            chartRenderer.loadData(jsonData);
            chartRenderer.render();
        }
    }


    $(function () {
        var ChartPluginInstance = new Facetbase.Chart.Plugin();
    });
}

